import React from "react";
import { MDBCarousel, MDBCarouselCaption, MDBCarouselInner, MDBCarouselItem, MDBView, MDBMask, MDBContainer } from
"mdbreact";
import pic1 from '../../images/acorn-2555291__480.webp';
import pic2 from '../../images/black-88037__480.jpg';
import pic3 from '../../images/brace-441376__480.webp';

const CarouselPage = () => {
  return (
    <MDBContainer className="carouselContainer">
      <MDBCarousel
      activeItem={1}
      length={3}
      showControls={true}
      showIndicators={true}
      className="z-depth-1"
    >
      <MDBCarouselInner>
        <MDBCarouselItem itemId="1">
          <MDBView>
            <img
              className="d-block w-100 carouselPic"
              src={pic1}
              alt="First slide"
            />
          <MDBMask overlay="black-light"  className="flex-center"/>
          </MDBView>
          <MDBCarouselCaption>
            <h3 className="h3-responsive carouselTitleY">Where balance is key</h3>
            <p className="carouselTextY">At Alpine and Ramona Oak Dental our philosophy is to provide treatment to the whole person - with a special focus on how your teeth and gums relate to your total body health. We want you to be confident that your smile achieves your goals for comfort, function and appearance. We're here to make that happen.</p>
          </MDBCarouselCaption>
        </MDBCarouselItem>
        <MDBCarouselItem itemId="2">
          <MDBView>
            <img
              className="d-block w-100 carouselPic"
              src={pic2}
              alt="Second slide"
            />
          <MDBMask overlay="black-strong" />
          </MDBView>
          <MDBCarouselCaption>
            <h3 className="h3-responsive carouselTitleY">Where balance is key</h3>
            <p className="carouselTextY">At Oak Dental our philosophy is to provide treatment to the whole person - with a special focus on how your teeth and gums relate to your total body health. We want you to be confident that your smile achieves your goals for comfort, function and appearance. We're here to make that happen.</p>
          </MDBCarouselCaption>
        </MDBCarouselItem>
        <MDBCarouselItem itemId="3">
          <MDBView>
            <img
              className="d-block w-100 carouselPic"
              src={pic3}
              alt="Third slide"
            />
          <MDBMask overlay="black-slight" />
          </MDBView>
          <MDBCarouselCaption>
            <h3 className="h3-responsive carouselTitleY">Where balance is key</h3>
            <p className="carouselTextY">At Oak Dental our philosophy is to provide treatment to the whole person - with a special focus on how your teeth and gums relate to your total body health. We want you to be confident that your smile achieves your goals for comfort, function and appearance. We're here to make that happen.</p>
          </MDBCarouselCaption>
        </MDBCarouselItem>
      </MDBCarouselInner>
    </MDBCarousel>
    </MDBContainer>
  );
}

export default CarouselPage;

