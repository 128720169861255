import React from 'react';
import emailjs from 'emailjs-com';
import {MDBContainer, MDBRow, MDBCol, MDBBtn, MDBIcon} from 'mdbreact';

const SERVICE_ID = process.env.REACT_APP_EMAILJS_SERVICE_ID;
const TEMPLATE_ID = process.env.REACT_APP_EMAILJS_TEMPLATE_ID;
const USER_ID = process.env.REACT_APP_EMAILJS_USER_ID_API_KEY;

//import './ContactUs.css';

export default function ContactUs() {

  function sendEmail(e) {
    e.preventDefault();

    emailjs.sendForm(SERVICE_ID, TEMPLATE_ID, e.target, USER_ID)
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
      e.target.reset()
      alert("Your message has been sent")
  }

  /*return (
    <form className="contact-form" onSubmit={sendEmail}>
      <input type="hidden" name="contact_number" />
      <label>Name</label>
      <input type="text" name="user_name" /><br/>
      <label>Email</label>
      <input type="email" name="user_email" /><br/>
      <label>Message</label>
      <textarea name="message" /><br/>
      <input type="submit" value="Send" />
    </form>
  );*/
  return (
    <MDBContainer className="emailContainer" >
      <MDBRow>
        <MDBCol md="6" className="emailForm">
          <form  onSubmit={sendEmail}>
            <p className="h4 text-center mb-4">Write to us</p>
            <label htmlFor="defaultFormContactNameEx" className="grey-text">
              Your name
            </label>
            <input type="text" id="defaultFormContactNameEx" className="form-control" name="user_name" />
            <br />
            <label htmlFor="defaultFormContactEmailEx" className="grey-text">
              Your email
            </label>
            <input type="email" id="defaultFormContactEmailEx" className="form-control" name="user_email" />
            <br />
            <label htmlFor="defaultFormContactSubjectEx" className="grey-text">
              Subject
            </label>
            <input type="text" id="defaultFormContactSubjectEx" className="form-control" name="subject"/>
            <br />
            <label htmlFor="defaultFormContactMessageEx" className="grey-text">
              Your message
            </label>
            <textarea type="text" id="defaultFormContactMessageEx" className="form-control" rows="3" name="message" />
            <div className="text-center mt-4">
                      <MDBBtn id="emailSubmit" outline type="submit">
                        Send
                        <MDBIcon far icon="paper-plane" className="ml-2" />
                      </MDBBtn>
                    </div>
                  </form>
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          );
}