import React from 'react';
import Image from 'react-bootstrap/Image';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Book from '../../images/book-4600757__480.webp';

export default function MeetTheDoctor(props){
    return <Container className="meetTheDoctorContainer">
    <Row>
      <Col xs={6} md={8} className="meetTheDoctorImg">
        <Image src={Book} rounded />
      </Col>
      </Row>
      <Row>
      <div className="meetTheDoctorText">
        <div>
          Dr. Rodolfo Orozco: <br/><br/>
        </div>
        <div>
          I am proud to have served patients in our community for over 30 years. The combination of continuing education and state-of-the-art equipment allow us to offer you and your family the high level of care you're looking for. I am a firm believer that as soon as the process of acquiring knowledge stops, the process of becoming obsolete begins. My team and I will give you a warm welcome and our ongoing commitment to your dental health.
       </div>
     </div>
      



    </Row>
    
  </Container>
}
