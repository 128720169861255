import React from 'react';
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";

export default function Services(props){
    return (<MDBContainer >
        <MDBRow className="officeLocationsAndHours">
     
        
     
     
         <MDBCol md="3" className="weeklyScheduleTitle">SERVICES OFFERED</MDBCol>
         <MDBCol md="9" ><br/><br/></MDBCol>
         <MDBCol md="3" className="dayOfWeek">Hygiene</MDBCol>
         <MDBCol md="9" >(including managing Periodontal Disease)<br/><br/></MDBCol>
         <MDBCol md="3" className="dayOfWeek">Cosmetic and General Dentistry</MDBCol>
         <MDBCol md="9">(fillings, crowns) <br/>(EMERGENCIES - same day appointment)<br/><br/></MDBCol>
         <MDBCol md="3" className="dayOfWeek">Endodontics</MDBCol>
         <MDBCol md="9">(root canals, etc.)<br/><br/></MDBCol>
         <MDBCol md="3" className="dayOfWeek">Oral Surgery</MDBCol>
         <MDBCol md="9">(wisdom teeth removal, etc.)<br/><br/></MDBCol> <MDBCol md="3" className="dayOfWeek">Implants</MDBCol>
         <MDBCol md="9">(all stages, from start to finish)<br/><br/></MDBCol>
         <MDBCol md="3" className="dayOfWeek">Orthodontics</MDBCol>
         <MDBCol md="9">(braces, etc.)<br/><br/></MDBCol>
         <MDBCol md="3" className="dayOfWeek">Temporomandibular Joint Dysfunction (TMJ/TMD)</MDBCol>
         <MDBCol md="9">(appliances, etc.)<br/><br/></MDBCol> 
         <MDBCol md="3" className="dayOfWeek">Family Dentistry</MDBCol>
         <MDBCol md="9">(ages 3 - 103, servings patients at all stages of life)<br/><br/></MDBCol>
         
         
         
       </MDBRow>
     </MDBContainer>)
}

/*<div className="servicesContainer"> 
        <ListGroup as="ul" className="servicesList">
  <ListGroup.Item as="li" >
    Hygiene (including managing periodontal disease)
  </ListGroup.Item>
  <ListGroup.Item as="li">Cosmetic and General Dentristry (fillings, crowns, ect.)</ListGroup.Item>
  <ListGroup.Item as="li" >
   Endodontics (root canals, implants start to finish)

  </ListGroup.Item>
  <ListGroup.Item as="li">Oral Surgery (wisdom teeth)</ListGroup.Item>
  <ListGroup.Item as="li">Orthodontics (braces, ect.)</ListGroup.Item>
  <ListGroup.Item as="li">Family Dentistry (ages 3 -103)</ListGroup.Item>
</ListGroup>
    </div> */