import React from "react";
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import EmailForm from './../emailForm/emailForm';

const LocationsAndHours = () => {
return (
<MDBContainer >
<MDBCol md="6" className="emailFormInLocationsPg"><EmailForm/><br/><br/></MDBCol>
   <MDBRow className="officeLocationsAndHours">

    <MDBCol md="3" className="officeLocationsTitle">OFFICE LOCATIONS</MDBCol>
    <MDBCol md="9" ></MDBCol>
    <MDBCol md="3" className="addresses">Alpine Oak Dental</MDBCol>
    <MDBCol md="9" >2065 Arnold Way #105,<br/>Alpine, CA 91901<br/> <br/><a href="tel:(760-440-9000)">(619)-659-9580</a><br/><br/></MDBCol>
    <MDBCol md="3">Ramona Oak Dental</MDBCol>
    <MDBCol md="9">1721 Main St #102,<br/>Ramona, CA 92065<br/><br/><a href="tel:(760-440-9000)">(760)-440-9000</a><br/><br/></MDBCol>
  


    <MDBCol md="3" className="weeklyScheduleTitle">WEEKLY SCHEDULE</MDBCol>
    <MDBCol md="9" ><br/><br/></MDBCol>
    <MDBCol md="3" className="dayOfWeek">Monday</MDBCol>
    <MDBCol md="9">Alpine: 9am-5pm <br/>Ramona: by appointment<br/><br/></MDBCol>
    
    <MDBCol md="3" className="dayOfWeek">Tuesday</MDBCol>
    <MDBCol md="9">Alpine: by appointment <br/>Ramona: 9am-5pm<br/><br/></MDBCol>
    <MDBCol md="3" className="dayOfWeek">Wednesday</MDBCol>
    <MDBCol md="9">Alpine: 11am-7pm <br/>Ramona: by appointment<br/><br/></MDBCol>
    <MDBCol md="3" className="dayOfWeek">Thursday</MDBCol>
    <MDBCol md="9">Alpine: by appointment <br/>Ramona: 9am-5pm<br/><br/></MDBCol>
    <MDBCol md="3" className="dayOfWeek">Friday</MDBCol>
    <MDBCol md="9">Alpine: by appointment <br/>Ramona: by appointment<br/><br/></MDBCol>
   
  </MDBRow>
  
</MDBContainer>

);
}

export default LocationsAndHours;