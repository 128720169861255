import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import logo from './logo.svg';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import TopNav from './components/topNav/topNav';
import Home from './components/home/home';
import TripleCardFinalY from './components/tripleCardY/tripleCardFinalY';
import MeetTheDoctor from './components/meetTheDoctor/meetTheDoctor';
import Services from './components/services/services';
import Error from './components/error/error';
import LocationsAndHours from './components/locationsAndHours/locationsAndHours';
import NewNav from './components/newNav/newNav';
import MeetTheStaff from './components/meetTheStaff/meetTheStaff';
import ContactUs from './components/emailForm/emailForm';

function App() {
  return (
   
      <main>
        <div className="appContainer" >
        <TopNav/>
     
       <div className="appContentContainer" id="appCellContainer">
       <Switch>
           
           
          
           <Route path="/" component={Home} exact />
           <Route path="/meetTheDoctor" component={MeetTheDoctor} exact  />
           <Route path="/meetTheStaff" component={MeetTheStaff} exact />
           <Route path="/locationsAndHours" component={LocationsAndHours} />
           <Route path="/services" component={Services} />
           
           <Route component={Error} />
       </Switch>
       <div className="tripleCardFinalBackgroundY"> <TripleCardFinalY/></div>
       
       <div><ContactUs/></div>
      
       <div className="iconDues">Icons made by <a href="https://www.flaticon.com/<?=_('authors/')?>freepik" title="Freepik">Freepik</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></div>
       </div>
        </div>
        
       
        </main>
    
      

    
  );
}

export default App;
